import { ITractableTheme } from "@tractable/frame-ui";

/** Common styles used across numerous components */
export const commonStyles = (theme: ITractableTheme) => {
  return {
    tractableLogo: {
      marginBottom: "32px",
    },
    container: {
      height: "100vh",
    },
    card: {
      borderWidth: "1px",
      borderColor: theme.colour.Grey5,
      borderRadius: "7px",
      padding: "48px",
      minWidth: "421px",
    },
    cardTitle: {
      marginBottom: "24px",
      fontSize: "24px",
      fontWeight: 500,
      color: theme.colour.TextPrimary,
    },
    explanation: {
      marginBottom: "24px",
    },
    textInput: {
      "& .mantine-TextInput-input": {
        borderRadius: "2px",
        borderWidth: "1px",
        "&:focus": {
          borderColor: theme.colour.Purple80,
        },
      },
    },
    submitButton: {
      marginTop: "24px",
      backgroundColor: theme.colour.Purple60,
      borderRadius: "4px",
      borderWidth: "1px",
      borderColor: theme.colour.Black,
      "&:hover, &:disabled": {
        backgroundColor: theme.colour.Purple60,
      },
    },
  };
};
