import { Center, Loader } from "@mantine/core";
import {
  LoginRequestEnvEnum,
  PasswordPolicy,
} from "@tractableai/auth-identity-broker-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useSearchParams } from "react-router-dom";

import { ApiClientContext } from "./context/ApiClientProvider";
import NotFound from "./NotFound";
import { errorNotification } from "./notifications";

// Props that are injected into the wrapped component
export type WithPasswordPolicyProps = {
  clientId: string;
  env: LoginRequestEnvEnum;
  passwordPolicy: PasswordPolicy;
};

/** Wrapper component that ensures that all required query params are present
 * and fetches the user pool's password policy so the wrapped component can use
 * it in validation */
export function withPasswordPolicy<
  A extends WithPasswordPolicyProps = WithPasswordPolicyProps
>(Component: React.ComponentType<A>) {
  return (otherProps: Omit<A, "clientId" | "env" | "passwordPolicy">) => {
    const [searchParams] = useSearchParams();

    const [passwordPolicy, setPasswordPolicy] = useState<
      PasswordPolicy | undefined
    >(undefined);

    const { apiClient } = ApiClientContext.useValue();

    const clientId = searchParams.get("client_id");
    const env = searchParams.get("env") as LoginRequestEnvEnum;

    const { t } = useTranslation();

    if (!clientId || !env) {
      return <NotFound />;
    }

    useEffect(() => {
      if (apiClient) {
        apiClient
          .getUserPool({
            clientId,
            env,
          })
          .then((pool) => {
            setPasswordPolicy(pool.passwordPolicy);
          })
          .catch((e) => {
            console.error(e);
            errorNotification(t("error.generic"));
          });
      }
    }, [apiClient]);

    const classes = useStyles();

    let content = <Loader />;
    if (passwordPolicy) {
      const wrappedComponentProps = {
        clientId,
        env,
        passwordPolicy,
        ...otherProps,
      } as A;
      content = <Component {...wrappedComponentProps} />;
    }

    return <Center className={classes.container}>{content}</Center>;
  };
}

const useStyles = createUseStyles({
  container: {
    height: "100vh",
  },
});
