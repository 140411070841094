/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmForgotPasswordRequest
 */
export interface ConfirmForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    username: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    env: ConfirmForgotPasswordRequestEnvEnum;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    clientId: string;
    /**
     * ID for a Tractable product
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    productId: string;
    /**
     * Confirmation code obtained from an email to the user
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    confirmationCode: string;
    /**
     * New password to set
     * @type {string}
     * @memberof ConfirmForgotPasswordRequest
     */
    password: string;
}

/**
* @export
* @enum {string}
*/
export type ConfirmForgotPasswordRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function ConfirmForgotPasswordRequestFromJSON(json: any): ConfirmForgotPasswordRequest {
    return ConfirmForgotPasswordRequestFromJSONTyped(json, false);
}

export function ConfirmForgotPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmForgotPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'env': json['env'],
        'clientId': json['clientId'],
        'productId': json['productId'],
        'confirmationCode': json['confirmationCode'],
        'password': json['password'],
    };
}

export function ConfirmForgotPasswordRequestToJSON(value?: ConfirmForgotPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'env': value.env,
        'clientId': value.clientId,
        'productId': value.productId,
        'confirmationCode': value.confirmationCode,
        'password': value.password,
    };
}

