/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * Username for a native Cognito account
     * @type {string}
     * @memberof LoginRequest
     */
    username: string;
    /**
     * Password for a native Cognito account
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * ID for a Tractable product
     * @type {string}
     * @memberof LoginRequest
     */
    productId: string;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof LoginRequest
     */
    clientId: string;
    /**
     * ID of a client to impersonate (for TR users only)
     * @type {string}
     * @memberof LoginRequest
     */
    impersonatedClientId?: string;
    /**
     * PKCE code challenge
     * @type {string}
     * @memberof LoginRequest
     */
    codeChallenge: string;
    /**
     * URI to redirect to with authorization code after logging in
     * @type {string}
     * @memberof LoginRequest
     */
    redirectUri: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof LoginRequest
     */
    env: LoginRequestEnvEnum;
}

/**
* @export
* @enum {string}
*/
export type LoginRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function LoginRequestFromJSON(json: any): LoginRequest {
    return LoginRequestFromJSONTyped(json, false);
}

export function LoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'productId': json['productId'],
        'clientId': json['clientId'],
        'impersonatedClientId': !exists(json, 'impersonatedClientId') ? undefined : json['impersonatedClientId'],
        'codeChallenge': json['codeChallenge'],
        'redirectUri': json['redirectUri'],
        'env': json['env'],
    };
}

export function LoginRequestToJSON(value?: LoginRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'productId': value.productId,
        'clientId': value.clientId,
        'impersonatedClientId': value.impersonatedClientId,
        'codeChallenge': value.codeChallenge,
        'redirectUri': value.redirectUri,
        'env': value.env,
    };
}

