/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyTokenRequest
 */
export interface VerifyTokenRequest {
    /**
     * JWT token: it can be either an access or an id token
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    token: string;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    clientId: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    env: VerifyTokenRequestEnvEnum;
    /**
     * ID of the product
     * @type {string}
     * @memberof VerifyTokenRequest
     */
    productId: string;
    /**
     * If present, membership in at least one of these Cognito groups will be required
     * @type {Array<string>}
     * @memberof VerifyTokenRequest
     */
    groups?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export type VerifyTokenRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function VerifyTokenRequestFromJSON(json: any): VerifyTokenRequest {
    return VerifyTokenRequestFromJSONTyped(json, false);
}

export function VerifyTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyTokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'clientId': json['clientId'],
        'env': json['env'],
        'productId': json['productId'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
    };
}

export function VerifyTokenRequestToJSON(value?: VerifyTokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'clientId': value.clientId,
        'env': value.env,
        'productId': value.productId,
        'groups': value.groups,
    };
}

