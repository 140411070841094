import { Card, Center, List, Text } from "@mantine/core";
import { CheckFilled, ITractableTheme } from "@tractable/frame-ui";
import { Colour } from "@tractable/frame-ui/build/theme/colour";
import { IconSize } from "@tractable/frame-ui/build/theme/size";
import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { commonStyles } from "./styles";

const AccountCreated = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("create_account.title");
  }, [t]);
  const classes = useStyles();

  return (
    <Center className={classes.container}>
      <Card
        className={classNames(classes.card, classes.accountCreatedCard)}
        shadow="lg"
      >
        <Center className={classes.tractableLogo}>
          <CheckFilled colour={Colour.Green40} size={IconSize.LARGE} />
        </Center>
        <Text ta="center" className={classes.cardTitle}>
          {t("create_account.success.header")}
        </Text>
        <Text className={classes.accountCreatedExplanation}>
          {t("create_account.success.body1")}
        </Text>
        <List>
          <List.Item>{t("create_account.success.body2")}</List.Item>
          <List.Item>{t("create_account.success.body3")}</List.Item>
          <List.Item>{t("create_account.success.body4")}</List.Item>
        </List>
      </Card>
    </Center>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  accountCreatedCard: {
    width: "421px",
  },
  accountCreatedExplanation: {
    marginBottom: "12px",
  },
}));

export default AccountCreated;
