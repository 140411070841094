/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IdentityProvider,
    IdentityProviderFromJSON,
    IdentityProviderFromJSONTyped,
    IdentityProviderToJSON,
} from './IdentityProvider';

/**
 * 
 * @export
 * @interface IdentityProviderResponse
 */
export interface IdentityProviderResponse {
    /**
     * 
     * @type {Array<IdentityProvider>}
     * @memberof IdentityProviderResponse
     */
    identityProviders: Array<IdentityProvider>;
}


export function IdentityProviderResponseFromJSON(json: any): IdentityProviderResponse {
    return IdentityProviderResponseFromJSONTyped(json, false);
}

export function IdentityProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identityProviders': ((json['identityProviders'] as Array<any>).map(IdentityProviderFromJSON)),
    };
}

export function IdentityProviderResponseToJSON(value?: IdentityProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identityProviders': ((value.identityProviders as Array<any>).map(IdentityProviderToJSON)),
    };
}

