/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    username: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    env: ResetPasswordRequestEnvEnum;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    clientId: string;
}

/**
* @export
* @enum {string}
*/
export type ResetPasswordRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function ResetPasswordRequestFromJSON(json: any): ResetPasswordRequest {
    return ResetPasswordRequestFromJSONTyped(json, false);
}

export function ResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'env': json['env'],
        'clientId': json['clientId'],
    };
}

export function ResetPasswordRequestToJSON(value?: ResetPasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'env': value.env,
        'clientId': value.clientId,
    };
}

