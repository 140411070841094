import {
  Button,
  Card,
  Center,
  Loader,
  Overlay,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ITractableTheme } from "@tractable/frame-ui";
import { colours } from "@tractable/frame-ui/build/theme/colour";
import { ResponseError } from "@tractableai/auth-identity-broker-client";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useSearchParams } from "react-router-dom";

import { ApiClientContext } from "./context/ApiClientProvider";
import { TractableLogo } from "./icons/TractableLogo";
import NotFound from "./NotFound";
import { errorNotification } from "./notifications";
import PasswordValidation, {
  calculateNewPasswordValidity,
} from "./PasswordValidation";
import { commonStyles } from "./styles";
import { navigateToExternalUrl } from "./utils/url";
import {
  withPasswordPolicy,
  WithPasswordPolicyProps,
} from "./withPasswordPolicy";

type ConfirmAccountProps = WithPasswordPolicyProps;

export type ConfirmAccountForm = {
  // NOTE: the Cognito API requires the parameter to be called 'username' so
  // we're using that here, but in practice this will be an email address for
  // pools that only allow email logins (which should be most of them)
  username: string;
  currentPassword: string;
  newPassword: string;
  familyName: string;
  givenName: string;
};

const ConfirmAccount = (props: ConfirmAccountProps) => {
  const [loading, setLoading] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const { apiClient } = ApiClientContext.useValue();
  const { clientId, env, passwordPolicy } = props;
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const productId = searchParams.get("product_id");
  const redirectUri = searchParams.get("redirect_uri");
  const email = searchParams.get("email");

  if (!productId || !redirectUri) {
    return <NotFound />;
  }

  useEffect(() => {
    document.title = t("create_account.title");
  }, [t]);

  const form = useForm<ConfirmAccountForm>({
    initialValues: {
      username: email ?? "",
      currentPassword: "",
      newPassword: "",
      givenName: "",
      familyName: "",
    },
    validate: (values: ConfirmAccountForm) => {
      // Only show one error message at a time, per the spec
      if (!values.username) {
        return {
          username: t("create_account.error.email"),
        };
      }

      if (!values.currentPassword) {
        return {
          currentPassword: t("create_account.error.temp_password"),
        };
      }

      if (!values.givenName) {
        return { givenName: t("create_account.error.first_name") };
      }

      if (!values.familyName) {
        return { familyName: t("create_account.error.last_name") };
      }

      const newPasswordValidity = calculateNewPasswordValidity(
        passwordPolicy,
        values.newPassword
      );

      return {
        newPassword: Object.values(newPasswordValidity).includes(false)
          ? t("create_account.error.create_password")
          : null,
      };
    },
  });

  const handleSubmit = async (values: ConfirmAccountForm) => {
    setLoading(true);
    const { username, currentPassword, newPassword, givenName, familyName } =
      values;
    try {
      await apiClient.confirmAccount({
        confirmAccountRequest: {
          clientId,
          productId,
          env,
          currentPassword,
          newPassword,
          username,
          givenName,
          familyName,
        },
      });
      setAccountCreated(true);

      // Wait a couple seconds before redirecting to the login screen. Although
      // this isn't strictly necessary, we thought it would make the user
      // experience easier to understand.
      setTimeout(() => navigateToExternalUrl(redirectUri), 2000);
    } catch (e) {
      if (e instanceof ResponseError && e.response.status === 403) {
        errorNotification(t("login.error.invalid"));
      } else if (e instanceof ResponseError && e.response.status === 429) {
        errorNotification(t("error.too_many_failed_attempts"));
      } else {
        errorNotification(t("error.generic"));
      }
      setLoading(false);
    }
  };

  const classes = useStyles();

  if (!passwordPolicy) {
    // TODO(kevin): replace with full-screen loader?
    return <Loader />;
  }

  const newPasswordValidity = calculateNewPasswordValidity(
    passwordPolicy,
    form.values.newPassword
  );

  const givenNameField = (
    <TextInput
      label={t("create_account.first_name")}
      className={classNames(classes.textInput, classes.notLastTextInput)}
      disabled={loading}
      {...form.getInputProps("givenName")}
    />
  );

  const familyNameField = (
    <TextInput
      label={t("create_account.last_name")}
      className={classNames(classes.textInput, classes.notLastTextInput)}
      disabled={loading}
      {...form.getInputProps("familyName")}
    />
  );

  return (
    <Card className={classes.card} shadow="lg">
      {accountCreated && (
        <div>
          <Card className={classes.loadingCard}>
            <Loader size="xl" color={colours.purple[60]} />
            <div>
              <Trans
                i18nKey="create_account.redirect"
                components={[<a href={redirectUri} />]}
              />
            </div>
          </Card>
          <Overlay color={colours.grey[30]} />
        </div>
      )}
      <Center className={classes.tractableLogo}>
        <TractableLogo />
      </Center>
      <Text ta="center" className={classes.cardTitle}>
        {t("create_account.welcome")}
      </Text>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label={t("create_account.email")}
          className={classNames(classes.textInput, classes.notLastTextInput)}
          disabled={loading}
          {...form.getInputProps("username")}
        />
        <PasswordInput
          label={t("create_account.temporary_password")}
          className={classNames(classes.textInput, classes.notLastTextInput)}
          disabled={loading}
          {...form.getInputProps("currentPassword")}
        />
        {i18n.language === "ja" ? (
          <>
            {familyNameField}
            {givenNameField}
          </>
        ) : (
          <>
            {givenNameField}
            {familyNameField}
          </>
        )}
        <PasswordInput
          label={t("create_account.new_password")}
          className={classNames(classes.textInput, classes.lastTextInput)}
          disabled={loading}
          {...form.getInputProps("newPassword")}
        />
        <PasswordValidation
          validity={newPasswordValidity}
          minimumLength={passwordPolicy.minimumLength}
          passwordPresent={form.values.newPassword.length > 0}
        />
        <Button
          className={classes.submitButton}
          type="submit"
          disabled={loading}
          fullWidth={true}
        >
          {loading ? (
            <Loader color="white" size="xs" />
          ) : (
            t("create_account.button")
          )}
        </Button>
      </form>
    </Card>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  notLastTextInput: {
    marginBottom: "24px",
  },
  lastTextInput: {
    marginBottom: "8px",
  },
  errorAlert: {
    marginTop: "8px",
  },
  loadingCard: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    gap: "24px",
    padding: "48px 32px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colour.White,
    color: theme.colour.Black,
    zIndex: 300,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "24px",
    width: "300px",
  },
}));

export default withPasswordPolicy(ConfirmAccount);
