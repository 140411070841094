/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmAccountRequest
 */
export interface ConfirmAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    newPassword: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    env: ConfirmAccountRequestEnvEnum;
    /**
     * ID for a Tractable product
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    productId: string;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmAccountRequest
     */
    givenName: string;
}

/**
* @export
* @enum {string}
*/
export type ConfirmAccountRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function ConfirmAccountRequestFromJSON(json: any): ConfirmAccountRequest {
    return ConfirmAccountRequestFromJSONTyped(json, false);
}

export function ConfirmAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmAccountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'currentPassword': json['currentPassword'],
        'newPassword': json['newPassword'],
        'env': json['env'],
        'productId': json['productId'],
        'clientId': json['clientId'],
        'familyName': json['familyName'],
        'givenName': json['givenName'],
    };
}

export function ConfirmAccountRequestToJSON(value?: ConfirmAccountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
        'env': value.env,
        'productId': value.productId,
        'clientId': value.clientId,
        'familyName': value.familyName,
        'givenName': value.givenName,
    };
}

