import { ITractableTheme } from "@tractable/frame-ui";
import { PropsWithChildren } from "react";
import { createUseStyles } from "react-jss";

type ContainerProps = PropsWithChildren<Record<never, never>>;

const Container = (props: ContainerProps) => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  container: {
    backgroundColor: theme.colour.Grey5,
    height: "100vh",
  },
}));

export default Container;
