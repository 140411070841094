const emailRegexps = [
  {
    clientId: "tractable",
    regexp: /@tractable\.(ai|io)$/,
  },
  {
    clientId: "geico",
    regexp: /@geico.com$/,
  },
];

/** Try to determine the clientId for a given email address; return undefined if
 * we can't determine it. */
export function guessClientId(email: string): string | undefined {
  return emailRegexps.find((entry) => entry.regexp.test(email))?.clientId;
}
