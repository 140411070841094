import { t } from "i18next";

/** Given an "internal" IdP name, return a user-friendly name to display on the
 * login button. If we don't have a user-friendly name registered, just return
 * the name as given.
 *
 * The internal names that are the domain of this function are defined in the
 * userpool CDK repo, and we know what external service (e.g. Microsoft) they
 * represent.
 */
export function idpDisplayName(idpName: string): string {
  switch (idpName) {
    case "AzureADSAML":
    case "AzureAD":
    case "AzureAD-PreProd":
    case "AzureAD-Prod":
      return t("ssoProvider.microsoft");
    default:
      return idpName;
  }
}
