import { Alert } from "@mantine/core";
import { ITractableTheme } from "@tractable/frame-ui";
import classNames from "classnames";
import { createUseStyles } from "react-jss";

type ErrorAlertProps = {
  message: string;
  className?: string;
};

const ErrorAlert = (props: ErrorAlertProps) => {
  const classes = useStyles();

  return (
    <Alert className={classNames(classes.errorAlert, props.className)}>
      {props.message}
    </Alert>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  errorAlert: {
    backgroundColor: theme.colour.Red5,
    borderColor: theme.colour.Red10,
    borderRadius: "4px",
  },
}));

export default ErrorAlert;
