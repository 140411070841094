import {
  Button,
  Card,
  Center,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import { CheckFilled, ITractableTheme } from "@tractable/frame-ui";
import { Colour, colours } from "@tractable/frame-ui/build/theme/colour";
import { IconSize } from "@tractable/frame-ui/build/theme/size";
import { ResponseError } from "@tractableai/auth-identity-broker-client";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ApiClientContext } from "./context/ApiClientProvider";
import Check from "./icons/Check";
import { TractableLogo } from "./icons/TractableLogo";
import { LoginState } from "./Login";
import { errorNotification } from "./notifications";
import PasswordValidation, {
  calculateNewPasswordValidity,
} from "./PasswordValidation";
import { commonStyles } from "./styles";
import {
  withPasswordPolicy,
  WithPasswordPolicyProps,
} from "./withPasswordPolicy";

type ForgotPasswordProps = WithPasswordPolicyProps & {
  loginState?: LoginState;
  setLoginState: (state: LoginState) => void;
};

type ForgotPasswordForm = {
  email: string;
  password: string;
  confirmationCode: string;
};

type ForgotPasswordState = "enter_email" | "enter_new_password" | "success";

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { clientId, env, loginState, setLoginState, passwordPolicy } = props;
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("password_reset.title");
  }, [t]);

  // Allow skipping to the "enter new password" step when a given query string
  // param is set -- this will happen when the user clicks a link in an email
  // sent from TRAM
  const [step, setStep] = useState<ForgotPasswordState>(
    searchParams.get("step") === "confirm"
      ? "enter_new_password"
      : "enter_email"
  );

  const productId = "admin";

  const form = useForm<ForgotPasswordForm>({
    initialValues: {
      // When the user clicks the link in their "forgot password" email, it will
      // include their email address in this param so we know what value to use
      email: searchParams.get("email") ?? props.loginState?.email ?? "",
      password: "",
      confirmationCode: "",
    },
    validate: (values: ForgotPasswordForm) => {
      if (step === "enter_email") {
        return {
          email: /.@./.test(values.email)
            ? null
            : t("password_reset.error.email"),
        };
      } else if (step === "enter_new_password") {
        const newPasswordValidity = calculateNewPasswordValidity(
          passwordPolicy,
          values.password
        );
        return {
          password: Object.values(newPasswordValidity).includes(false)
            ? t("password_reset.error.create_password")
            : null,
          confirmationCode:
            values.confirmationCode.length > 0
              ? null
              : t("password_reset.code.error"),
        };
      }
      return {};
    },
  });

  const { apiClient } = ApiClientContext.useValue();
  const navigate = useNavigate();

  const classes = useStyles();

  const handleSubmit = async (values: ForgotPasswordForm) => {
    const { email, confirmationCode, password } = values;
    setLoading(true);
    try {
      if (step === "enter_email") {
        await apiClient.resetPassword({
          resetPasswordRequest: {
            clientId,
            env,
            username: email,
          },
        });
        setStep("enter_new_password");
      } else {
        await apiClient.confirmForgotPassword({
          confirmForgotPasswordRequest: {
            clientId,
            env,
            confirmationCode,
            password,
            productId,
            username: email,
          },
        });
        showNotification({
          autoClose: 4000,
          disallowClose: true,
          message: t("password_reset.toast"),
          icon: <Check colour={colours.white} />,
          styles: () => ({
            root: {
              backgroundColor: colours.black,
              borderColor: colours.black,
              // Hide the default blue bar on the left side of the popup
              "&::before": { display: "none" },
            },
            icon: {
              backgroundColor: `${colours.black} !important`,
            },
            description: { color: colours.white },
          }),
        });
        if (loginState) {
          setLoginState({
            ...loginState,
            step: "enter_password",
          });
        }

        form.reset();

        if (loginState) {
          // NOTE: we don't restore the query params (product_id etc) here, but
          // it's not necessary as the LoginComponent will prioritize the saved
          // LoginState
          navigate("/");
        } else {
          setStep("success");
        }
      }
    } catch (e) {
      console.error(e);
      if (e instanceof ResponseError && e.response.status === 429) {
        errorNotification(t("error.too_many_failed_attempts"));
      } else {
        errorNotification(t("error.generic"));
      }
    } finally {
      setLoading(false);
    }
  };

  const newPasswordValidity = calculateNewPasswordValidity(
    passwordPolicy,
    form.values.password
  );

  let header;
  let explanation;
  if (step === "enter_email") {
    header = t("password_reset.header");
    explanation = t("password_reset.body");
  } else if (step === "enter_new_password") {
    header = t("password_reset.header");
    explanation = t("password_reset.new.body");
  } else if (step === "success") {
    header = t("password_reset.success.header");
    explanation = t("password_reset.success.body");
  }
  return (
    <Card
      className={classNames(classes.card, classes.forgotPasswordCard)}
      shadow="lg"
    >
      <Center className={classes.tractableLogo}>
        {step === "success" ? (
          <CheckFilled colour={Colour.Green40} size={IconSize.LARGE} />
        ) : (
          <TractableLogo />
        )}
      </Center>
      <Text ta="center" className={classes.cardTitle}>
        {header}
      </Text>
      <Text className={classes.explanation}>{explanation}</Text>
      {step !== "success" && (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {step === "enter_email" && (
            <TextInput
              label={t("password_reset.email")}
              className={classes.textInput}
              disabled={loading}
              {...form.getInputProps("email")}
            />
          )}
          {step === "enter_new_password" && (
            <>
              <TextInput
                label={t("password_reset.code.confirmation_code")}
                className={classNames(
                  classes.textInput,
                  classes.confirmationCodeInput
                )}
                disabled={loading}
                {...form.getInputProps("confirmationCode")}
              />
              <PasswordInput
                label={t("password_reset.password")}
                className={classNames(classes.textInput)}
                disabled={loading}
                {...form.getInputProps("password")}
              />
              <PasswordValidation
                validity={newPasswordValidity}
                minimumLength={passwordPolicy.minimumLength}
                passwordPresent={form.values.password.length > 0}
              />
            </>
          )}
          <Button
            className={classes.submitButton}
            type="submit"
            disabled={loading}
            loading={loading}
            fullWidth={true}
          >
            {step === "enter_email"
              ? t("password_reset.send_code")
              : t("password_reset.reset")}
          </Button>
        </form>
      )}
    </Card>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  forgotPasswordCard: {
    // TODO(kevin): check with Betsy about this. Needs to be wider than 421 px
    // or the password validation doesn't fit
    width: "490px",
  },
  errorAlert: {
    marginTop: "8px",
  },
  confirmationCodeInput: {
    marginBottom: "24px",
  },
}));

export default withPasswordPolicy(ForgotPassword);
