import { showNotification } from "@mantine/notifications";
import { ExclamationIcon } from "@tractable/frame-ui";
import { colours } from "@tractable/frame-ui/build/theme/colour";

const _notify = (level: "info" | "error") => (message: string) => {
  const backgroundColor = colours.black;
  const textColor = colours.white;
  showNotification({
    autoClose: level === "info" ? 2000 : false,
    disallowClose: level === "info",
    message,
    styles: () => ({
      root: {
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        // Hide the default blue bar on the left side of the popup
        "&::before": { display: "none" },
      },
      description: { color: textColor },
      closeButton: { color: textColor },
      icon: { backgroundColor: "transparent!important", marginTop: "4px" },
    }),
    icon: level === "error" ? <ExclamationIcon /> : null,
  });
};

export const errorNotification = _notify("error");
