/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * OAuth authorization code
     * @type {string}
     * @memberof TokenRequest
     */
    code: string;
    /**
     * PKCE code verifier
     * @type {string}
     * @memberof TokenRequest
     */
    codeVerifier: string;
}


export function TokenRequestFromJSON(json: any): TokenRequest {
    return TokenRequestFromJSONTyped(json, false);
}

export function TokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'codeVerifier': json['codeVerifier'],
    };
}

export function TokenRequestToJSON(value?: TokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'codeVerifier': value.codeVerifier,
    };
}

