import "./index.css";

import { Loader, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { TractableTheme } from "@tractable/frame-ui";
import { Suspense, useState } from "react";
import { Route, Routes } from "react-router-dom";

import AccountCreated from "./AccountCreated";
import ConfirmAccount from "./ConfirmAccount";
import Container from "./Container";
import { ApiClientProvider } from "./context/ApiClientProvider";
import { ConfigProvider } from "./context/ConfigProvider";
import ForgotPassword from "./ForgotPassword";
import { FrameToJssThemeProvider } from "./FrameToJssThemeProvider";
import Login, { LoginState } from "./Login";
import Ping from "./Ping";

const App = () => {
  // When the user clicks the "forgot password" button while filling out the
  // login form, we store the state of that component here, then restore it
  // after the user resets their password and resumes the login form.
  const [loginState, setLoginState] = useState<LoginState | undefined>(
    undefined
  );

  return (
    <MantineProvider
      theme={{
        fontFamily:
          'IBMPlexSans,"Segoe UI",-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif !important',
      }}
    >
      <ConfigProvider>
        <ApiClientProvider>
          <TractableTheme>
            <FrameToJssThemeProvider>
              <NotificationsProvider position="bottom-center">
                <Container>
                  {/* Suspense for react-i18next's useTranslation */}
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <Login
                            loginState={loginState}
                            setLoginState={setLoginState}
                          />
                        }
                      />
                      <Route
                        path="/confirm_account"
                        element={<ConfirmAccount />}
                      />
                      <Route
                        path="/forgot_password"
                        element={
                          <ForgotPassword
                            loginState={loginState}
                            setLoginState={setLoginState}
                          />
                        }
                      />
                      <Route
                        path="/account_created"
                        element={<AccountCreated />}
                      />
                      <Route path="/ping" element={<Ping />} />
                    </Routes>
                  </Suspense>
                </Container>
              </NotificationsProvider>
            </FrameToJssThemeProvider>
          </TractableTheme>
        </ApiClientProvider>
      </ConfigProvider>
    </MantineProvider>
  );
};

export default App;
