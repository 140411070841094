/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    refreshToken: string;
    /**
     * Tractable environment to log in to
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    env: RefreshTokenRequestEnvEnum;
    /**
     * ID for a customer (not a Cognito app client)
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    clientId: string;
    /**
     * ID for a Tractable product
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    productId: string;
}

/**
* @export
* @enum {string}
*/
export type RefreshTokenRequestEnvEnum =
    'dev' | 
    'staging-eu' | 
    'preprod-eu' | 
    'prod-eu' | 
    'preprod-us' | 
    'prod-us' | 
    'preprod-jp' | 
    'prod-jp'



export function RefreshTokenRequestFromJSON(json: any): RefreshTokenRequest {
    return RefreshTokenRequestFromJSONTyped(json, false);
}

export function RefreshTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshTokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refreshToken': json['refreshToken'],
        'env': json['env'],
        'clientId': json['clientId'],
        'productId': json['productId'],
    };
}

export function RefreshTokenRequestToJSON(value?: RefreshTokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'refreshToken': value.refreshToken,
        'env': value.env,
        'clientId': value.clientId,
        'productId': value.productId,
    };
}

