// Import models
import {
    ConfirmAccountRequest,
    ConfirmAccountRequestFromJSON,
    ConfirmAccountRequestToJSON,
    ConfirmForgotPasswordRequest,
    ConfirmForgotPasswordRequestFromJSON,
    ConfirmForgotPasswordRequestToJSON,
    GenericError,
    GenericErrorFromJSON,
    GenericErrorToJSON,
    IdentityProviderResponse,
    IdentityProviderResponseFromJSON,
    IdentityProviderResponseToJSON,
    LoginExternalResponse,
    LoginExternalResponseFromJSON,
    LoginExternalResponseToJSON,
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    RefreshTokenRequest,
    RefreshTokenRequestFromJSON,
    RefreshTokenRequestToJSON,
    RefreshTokenResponse,
    RefreshTokenResponseFromJSON,
    RefreshTokenResponseToJSON,
    ResetPasswordRequest,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    TokenRequest,
    TokenRequestFromJSON,
    TokenRequestToJSON,
    TokenResponse,
    TokenResponseFromJSON,
    TokenResponseToJSON,
    UserPoolResponse,
    UserPoolResponseFromJSON,
    UserPoolResponseToJSON,
    VerifyToken200Response,
    VerifyToken200ResponseFromJSON,
    VerifyToken200ResponseToJSON,
    VerifyTokenRequest,
    VerifyTokenRequestFromJSON,
    VerifyTokenRequestToJSON,
} from '../../models';
// Import request parameter interfaces
import {
    CognitoCallbackRequest,
    ConfirmAccountOperationRequest,
    ConfirmForgotPasswordOperationRequest,
    GetUserPoolRequest,
    IdentityProvidersRequest,
    LoginOperationRequest,
    LoginExternalRequest,
    LogoutRequest,
    RefreshTokenOperationRequest,
    ResetPasswordOperationRequest,
    TokenOperationRequest,
    VerifyTokenOperationRequest,
} from '..';

// API Gateway Types
import { APIGatewayProxyEvent, APIGatewayProxyResult, Context } from "aws-lambda";

// Generic type for object keyed by operation names
export interface OperationConfig<T> {
    cognitoCallback: T;
    confirmAccount: T;
    confirmForgotPassword: T;
    getUserPool: T;
    identityProviders: T;
    login: T;
    loginExternal: T;
    logout: T;
    refreshToken: T;
    resetPassword: T;
    token: T;
    verifyToken: T;
}

// Look up path and http method for a given operation name
export const OperationLookup = {
    cognitoCallback: {
        path: '/cognito_callback',
        method: 'GET',
    },
    confirmAccount: {
        path: '/confirm_account',
        method: 'POST',
    },
    confirmForgotPassword: {
        path: '/confirm_forgot_password',
        method: 'POST',
    },
    getUserPool: {
        path: '/user_pool',
        method: 'GET',
    },
    identityProviders: {
        path: '/identity_providers',
        method: 'GET',
    },
    login: {
        path: '/login',
        method: 'POST',
    },
    loginExternal: {
        path: '/login_external',
        method: 'GET',
    },
    logout: {
        path: '/logout',
        method: 'POST',
    },
    refreshToken: {
        path: '/refresh_token',
        method: 'POST',
    },
    resetPassword: {
        path: '/reset_password',
        method: 'POST',
    },
    token: {
        path: '/token',
        method: 'POST',
    },
    verifyToken: {
        path: '/verify',
        method: 'POST',
    },
};

export class Operations {
  /**
   * Return an OperationConfig with the same value for every operation
   */
  public static all = <T>(value: T): OperationConfig<T> => Object.fromEntries(
    Object.keys(OperationLookup).map((operationId) => [operationId, value])
  ) as unknown as OperationConfig<T>;
}

// Standard apigateway request parameters (query parameters or path parameters, multi or single value)
type ApiGatewayRequestParameters = { [key: string]: string | string[] | undefined };

/**
 * URI decode for a string or array of strings
 */
const uriDecode = (value: string | string[]): string | string[] =>
    typeof value === 'string' ? decodeURIComponent(value) : value.map((v) => decodeURIComponent(v));

/**
 * URI decodes apigateway request parameters (query or path parameters)
 */
const decodeRequestParameters = (parameters: ApiGatewayRequestParameters): ApiGatewayRequestParameters => {
    const decodedParameters = {};
    Object.keys(parameters || {}).forEach((key) => {
        decodedParameters[key] = parameters[key] ? uriDecode(parameters[key]) : parameters[key];
    });
    return decodedParameters;
};

/**
 * Parse the body if the content type is json, otherwise leave as a raw string
 */
const parseBody = (body: string, demarshal: (body: string) => any, contentTypes: string[]): any => contentTypes.filter((contentType) => contentType !== 'application/json').length === 0 ? demarshal(body || '{}') : body;

type OperationIds = | 'cognitoCallback' | 'confirmAccount' | 'confirmForgotPassword' | 'getUserPool' | 'identityProviders' | 'login' | 'loginExternal' | 'logout' | 'refreshToken' | 'resetPassword' | 'token' | 'verifyToken';
type OperationApiGatewayProxyResult<T extends OperationIds> = APIGatewayProxyResult & { __operationId?: T };

// Api gateway lambda handler type
type OperationApiGatewayLambdaHandler<T extends OperationIds> = (event: APIGatewayProxyEvent, context: Context) => Promise<OperationApiGatewayProxyResult<T>>;

// Type of the response to be returned by an operation lambda handler
export interface OperationResponse<StatusCode extends number, Body> {
    statusCode: StatusCode;
    headers?: { [key: string]: string };
    body: Body;
}

// Input for a lambda handler for an operation
export type LambdaRequestParameters<RequestParameters, RequestArrayParameters, RequestBody> = {
    requestParameters: RequestParameters,
    requestArrayParameters: RequestArrayParameters,
    body: RequestBody,
};

export type InterceptorContext = { [key: string]: any };

export interface RequestInput<RequestParameters, RequestArrayParameters, RequestBody> {
    input: LambdaRequestParameters<RequestParameters, RequestArrayParameters, RequestBody>;
    event: APIGatewayProxyEvent;
    context: Context;
    interceptorContext: InterceptorContext;
}

export interface ChainedRequestInput<RequestParameters, RequestArrayParameters, RequestBody, Response> extends RequestInput<RequestParameters, RequestArrayParameters, RequestBody> {
    chain: LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response>;
}

/**
 * A lambda handler function which is part of a chain. It may invoke the remainder of the chain via the given chain input
 */
export type ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response> = (
  input: ChainedRequestInput<RequestParameters, RequestArrayParameters, RequestBody, Response>,
) => Promise<Response>;

// Type for a lambda handler function to be wrapped
export type LambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response> = (
  input: RequestInput<RequestParameters, RequestArrayParameters, RequestBody>,
) => Promise<Response>;

export interface LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response> {
  next: LambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>;
}

// Interceptor is a type alias for ChainedLambdaHandlerFunction
export type Interceptor<RequestParameters, RequestArrayParameters, RequestBody, Response> = ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>;

/**
 * Build a chain from the given array of chained lambda handlers
 */
const buildHandlerChain = <RequestParameters, RequestArrayParameters, RequestBody, Response>(
  ...handlers: ChainedLambdaHandlerFunction<RequestParameters, RequestArrayParameters, RequestBody, Response>[]
): LambdaHandlerChain<RequestParameters, RequestArrayParameters, RequestBody, Response> => {
  if (handlers.length === 0) {
    return {
      next: () => {
        throw new Error("No more handlers remain in the chain! The last handler should not call next.");
      }
    };
  }
  const [currentHandler, ...remainingHandlers] = handlers;
  return {
    next: (input) => {
      return currentHandler({
        ...input,
        chain: buildHandlerChain(...remainingHandlers),
      });
    },
  };
};

/**
 * Single-value path/query parameters for CognitoCallback
 */
export interface CognitoCallbackRequestParameters {
    readonly code: string;
    readonly state: string;
}

/**
 * Multi-value query parameters for CognitoCallback
 */
export interface CognitoCallbackRequestArrayParameters {
}

/**
 * Request body parameter for CognitoCallback
 */
export type CognitoCallbackRequestBody = never;

export type CognitoCallback302OperationResponse = OperationResponse<302, undefined>;
export type CognitoCallback400OperationResponse = OperationResponse<400, GenericError>;
export type CognitoCallback403OperationResponse = OperationResponse<403, GenericError>;
export type CognitoCallback500OperationResponse = OperationResponse<500, GenericError>;
export type CognitoCallbackOperationResponses = | CognitoCallback302OperationResponse | CognitoCallback400OperationResponse | CognitoCallback403OperationResponse | CognitoCallback500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type CognitoCallbackHandlerFunction = LambdaHandlerFunction<CognitoCallbackRequestParameters, CognitoCallbackRequestArrayParameters, CognitoCallbackRequestBody, CognitoCallbackOperationResponses>;
export type CognitoCallbackChainedHandlerFunction = ChainedLambdaHandlerFunction<CognitoCallbackRequestParameters, CognitoCallbackRequestArrayParameters, CognitoCallbackRequestBody, CognitoCallbackOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of cognitoCallback
 */
export const cognitoCallbackHandler = (
    firstHandler: CognitoCallbackChainedHandlerFunction,
    ...remainingHandlers: CognitoCallbackChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'cognitoCallback'> => async (event: any, context: any, _callback?: any, additionalInterceptors: CognitoCallbackChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as CognitoCallbackRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as CognitoCallbackRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as CognitoCallbackRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 302:
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ConfirmAccountOperation
 */
export interface ConfirmAccountOperationRequestParameters {
}

/**
 * Multi-value query parameters for ConfirmAccountOperation
 */
export interface ConfirmAccountOperationRequestArrayParameters {
}

/**
 * Request body parameter for ConfirmAccountOperation
 */
export type ConfirmAccountOperationRequestBody = ConfirmAccountRequest;

export type ConfirmAccountOperation200OperationResponse = OperationResponse<200, undefined>;
export type ConfirmAccountOperation400OperationResponse = OperationResponse<400, GenericError>;
export type ConfirmAccountOperation403OperationResponse = OperationResponse<403, GenericError>;
export type ConfirmAccountOperation429OperationResponse = OperationResponse<429, GenericError>;
export type ConfirmAccountOperation500OperationResponse = OperationResponse<500, GenericError>;
export type ConfirmAccountOperationOperationResponses = | ConfirmAccountOperation200OperationResponse | ConfirmAccountOperation400OperationResponse | ConfirmAccountOperation403OperationResponse | ConfirmAccountOperation429OperationResponse | ConfirmAccountOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ConfirmAccountOperationHandlerFunction = LambdaHandlerFunction<ConfirmAccountOperationRequestParameters, ConfirmAccountOperationRequestArrayParameters, ConfirmAccountOperationRequestBody, ConfirmAccountOperationOperationResponses>;
export type ConfirmAccountOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ConfirmAccountOperationRequestParameters, ConfirmAccountOperationRequestArrayParameters, ConfirmAccountOperationRequestBody, ConfirmAccountOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of confirmAccount
 */
export const confirmAccountHandler = (
    firstHandler: ConfirmAccountOperationChainedHandlerFunction,
    ...remainingHandlers: ConfirmAccountOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'confirmAccount'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ConfirmAccountOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ConfirmAccountOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ConfirmAccountOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ConfirmAccountRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ConfirmAccountOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 429:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ConfirmForgotPasswordOperation
 */
export interface ConfirmForgotPasswordOperationRequestParameters {
}

/**
 * Multi-value query parameters for ConfirmForgotPasswordOperation
 */
export interface ConfirmForgotPasswordOperationRequestArrayParameters {
}

/**
 * Request body parameter for ConfirmForgotPasswordOperation
 */
export type ConfirmForgotPasswordOperationRequestBody = ConfirmForgotPasswordRequest;

export type ConfirmForgotPasswordOperation200OperationResponse = OperationResponse<200, undefined>;
export type ConfirmForgotPasswordOperation400OperationResponse = OperationResponse<400, GenericError>;
export type ConfirmForgotPasswordOperation403OperationResponse = OperationResponse<403, GenericError>;
export type ConfirmForgotPasswordOperation429OperationResponse = OperationResponse<429, GenericError>;
export type ConfirmForgotPasswordOperation500OperationResponse = OperationResponse<500, GenericError>;
export type ConfirmForgotPasswordOperationOperationResponses = | ConfirmForgotPasswordOperation200OperationResponse | ConfirmForgotPasswordOperation400OperationResponse | ConfirmForgotPasswordOperation403OperationResponse | ConfirmForgotPasswordOperation429OperationResponse | ConfirmForgotPasswordOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ConfirmForgotPasswordOperationHandlerFunction = LambdaHandlerFunction<ConfirmForgotPasswordOperationRequestParameters, ConfirmForgotPasswordOperationRequestArrayParameters, ConfirmForgotPasswordOperationRequestBody, ConfirmForgotPasswordOperationOperationResponses>;
export type ConfirmForgotPasswordOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ConfirmForgotPasswordOperationRequestParameters, ConfirmForgotPasswordOperationRequestArrayParameters, ConfirmForgotPasswordOperationRequestBody, ConfirmForgotPasswordOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of confirmForgotPassword
 */
export const confirmForgotPasswordHandler = (
    firstHandler: ConfirmForgotPasswordOperationChainedHandlerFunction,
    ...remainingHandlers: ConfirmForgotPasswordOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'confirmForgotPassword'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ConfirmForgotPasswordOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ConfirmForgotPasswordOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ConfirmForgotPasswordOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ConfirmForgotPasswordRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ConfirmForgotPasswordOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 429:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for GetUserPool
 */
export interface GetUserPoolRequestParameters {
    readonly clientId: string;
    readonly env: string;
}

/**
 * Multi-value query parameters for GetUserPool
 */
export interface GetUserPoolRequestArrayParameters {
}

/**
 * Request body parameter for GetUserPool
 */
export type GetUserPoolRequestBody = never;

export type GetUserPool200OperationResponse = OperationResponse<200, UserPoolResponse>;
export type GetUserPool400OperationResponse = OperationResponse<400, GenericError>;
export type GetUserPool403OperationResponse = OperationResponse<403, GenericError>;
export type GetUserPool500OperationResponse = OperationResponse<500, GenericError>;
export type GetUserPoolOperationResponses = | GetUserPool200OperationResponse | GetUserPool400OperationResponse | GetUserPool403OperationResponse | GetUserPool500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type GetUserPoolHandlerFunction = LambdaHandlerFunction<GetUserPoolRequestParameters, GetUserPoolRequestArrayParameters, GetUserPoolRequestBody, GetUserPoolOperationResponses>;
export type GetUserPoolChainedHandlerFunction = ChainedLambdaHandlerFunction<GetUserPoolRequestParameters, GetUserPoolRequestArrayParameters, GetUserPoolRequestBody, GetUserPoolOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of getUserPool
 */
export const getUserPoolHandler = (
    firstHandler: GetUserPoolChainedHandlerFunction,
    ...remainingHandlers: GetUserPoolChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'getUserPool'> => async (event: any, context: any, _callback?: any, additionalInterceptors: GetUserPoolChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as GetUserPoolRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as GetUserPoolRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as GetUserPoolRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(UserPoolResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for IdentityProviders
 */
export interface IdentityProvidersRequestParameters {
    readonly productId: string;
    readonly clientId: string;
    readonly env: string;
}

/**
 * Multi-value query parameters for IdentityProviders
 */
export interface IdentityProvidersRequestArrayParameters {
}

/**
 * Request body parameter for IdentityProviders
 */
export type IdentityProvidersRequestBody = never;

export type IdentityProviders200OperationResponse = OperationResponse<200, IdentityProviderResponse>;
export type IdentityProviders400OperationResponse = OperationResponse<400, GenericError>;
export type IdentityProviders403OperationResponse = OperationResponse<403, GenericError>;
export type IdentityProviders500OperationResponse = OperationResponse<500, GenericError>;
export type IdentityProvidersOperationResponses = | IdentityProviders200OperationResponse | IdentityProviders400OperationResponse | IdentityProviders403OperationResponse | IdentityProviders500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type IdentityProvidersHandlerFunction = LambdaHandlerFunction<IdentityProvidersRequestParameters, IdentityProvidersRequestArrayParameters, IdentityProvidersRequestBody, IdentityProvidersOperationResponses>;
export type IdentityProvidersChainedHandlerFunction = ChainedLambdaHandlerFunction<IdentityProvidersRequestParameters, IdentityProvidersRequestArrayParameters, IdentityProvidersRequestBody, IdentityProvidersOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of identityProviders
 */
export const identityProvidersHandler = (
    firstHandler: IdentityProvidersChainedHandlerFunction,
    ...remainingHandlers: IdentityProvidersChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'identityProviders'> => async (event: any, context: any, _callback?: any, additionalInterceptors: IdentityProvidersChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as IdentityProvidersRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as IdentityProvidersRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as IdentityProvidersRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(IdentityProviderResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for LoginOperation
 */
export interface LoginOperationRequestParameters {
}

/**
 * Multi-value query parameters for LoginOperation
 */
export interface LoginOperationRequestArrayParameters {
}

/**
 * Request body parameter for LoginOperation
 */
export type LoginOperationRequestBody = LoginRequest;

export type LoginOperation200OperationResponse = OperationResponse<200, LoginResponse>;
export type LoginOperation400OperationResponse = OperationResponse<400, GenericError>;
export type LoginOperation403OperationResponse = OperationResponse<403, GenericError>;
export type LoginOperation429OperationResponse = OperationResponse<429, GenericError>;
export type LoginOperation500OperationResponse = OperationResponse<500, GenericError>;
export type LoginOperationOperationResponses = | LoginOperation200OperationResponse | LoginOperation400OperationResponse | LoginOperation403OperationResponse | LoginOperation429OperationResponse | LoginOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type LoginOperationHandlerFunction = LambdaHandlerFunction<LoginOperationRequestParameters, LoginOperationRequestArrayParameters, LoginOperationRequestBody, LoginOperationOperationResponses>;
export type LoginOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<LoginOperationRequestParameters, LoginOperationRequestArrayParameters, LoginOperationRequestBody, LoginOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of login
 */
export const loginHandler = (
    firstHandler: LoginOperationChainedHandlerFunction,
    ...remainingHandlers: LoginOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'login'> => async (event: any, context: any, _callback?: any, additionalInterceptors: LoginOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as LoginOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as LoginOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return LoginRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as LoginOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(LoginResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 429:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for LoginExternal
 */
export interface LoginExternalRequestParameters {
    readonly productId: string;
    readonly clientId: string;
    readonly redirectUri: string;
    readonly identityProvider: string;
    readonly codeChallenge: string;
    readonly env: string;
    readonly impersonatedClientId?: string;
}

/**
 * Multi-value query parameters for LoginExternal
 */
export interface LoginExternalRequestArrayParameters {
}

/**
 * Request body parameter for LoginExternal
 */
export type LoginExternalRequestBody = never;

export type LoginExternal200OperationResponse = OperationResponse<200, LoginExternalResponse>;
export type LoginExternal400OperationResponse = OperationResponse<400, GenericError>;
export type LoginExternal403OperationResponse = OperationResponse<403, GenericError>;
export type LoginExternal500OperationResponse = OperationResponse<500, GenericError>;
export type LoginExternalOperationResponses = | LoginExternal200OperationResponse | LoginExternal400OperationResponse | LoginExternal403OperationResponse | LoginExternal500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type LoginExternalHandlerFunction = LambdaHandlerFunction<LoginExternalRequestParameters, LoginExternalRequestArrayParameters, LoginExternalRequestBody, LoginExternalOperationResponses>;
export type LoginExternalChainedHandlerFunction = ChainedLambdaHandlerFunction<LoginExternalRequestParameters, LoginExternalRequestArrayParameters, LoginExternalRequestBody, LoginExternalOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of loginExternal
 */
export const loginExternalHandler = (
    firstHandler: LoginExternalChainedHandlerFunction,
    ...remainingHandlers: LoginExternalChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'loginExternal'> => async (event: any, context: any, _callback?: any, additionalInterceptors: LoginExternalChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as LoginExternalRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as LoginExternalRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as LoginExternalRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(LoginExternalResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for Logout
 */
export interface LogoutRequestParameters {
    readonly Authorization: string;
}

/**
 * Multi-value query parameters for Logout
 */
export interface LogoutRequestArrayParameters {
}

/**
 * Request body parameter for Logout
 */
export type LogoutRequestBody = never;

export type Logout200OperationResponse = OperationResponse<200, undefined>;
export type Logout400OperationResponse = OperationResponse<400, GenericError>;
export type Logout403OperationResponse = OperationResponse<403, GenericError>;
export type Logout500OperationResponse = OperationResponse<500, GenericError>;
export type LogoutOperationResponses = | Logout200OperationResponse | Logout400OperationResponse | Logout403OperationResponse | Logout500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type LogoutHandlerFunction = LambdaHandlerFunction<LogoutRequestParameters, LogoutRequestArrayParameters, LogoutRequestBody, LogoutOperationResponses>;
export type LogoutChainedHandlerFunction = ChainedLambdaHandlerFunction<LogoutRequestParameters, LogoutRequestArrayParameters, LogoutRequestBody, LogoutOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of logout
 */
export const logoutHandler = (
    firstHandler: LogoutChainedHandlerFunction,
    ...remainingHandlers: LogoutChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'logout'> => async (event: any, context: any, _callback?: any, additionalInterceptors: LogoutChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as LogoutRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as LogoutRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return {};
    };
    const body = parseBody(event.body, demarshal, ['application/json']) as LogoutRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for RefreshTokenOperation
 */
export interface RefreshTokenOperationRequestParameters {
}

/**
 * Multi-value query parameters for RefreshTokenOperation
 */
export interface RefreshTokenOperationRequestArrayParameters {
}

/**
 * Request body parameter for RefreshTokenOperation
 */
export type RefreshTokenOperationRequestBody = RefreshTokenRequest;

export type RefreshTokenOperation200OperationResponse = OperationResponse<200, RefreshTokenResponse>;
export type RefreshTokenOperation400OperationResponse = OperationResponse<400, GenericError>;
export type RefreshTokenOperation403OperationResponse = OperationResponse<403, GenericError>;
export type RefreshTokenOperation500OperationResponse = OperationResponse<500, GenericError>;
export type RefreshTokenOperationOperationResponses = | RefreshTokenOperation200OperationResponse | RefreshTokenOperation400OperationResponse | RefreshTokenOperation403OperationResponse | RefreshTokenOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type RefreshTokenOperationHandlerFunction = LambdaHandlerFunction<RefreshTokenOperationRequestParameters, RefreshTokenOperationRequestArrayParameters, RefreshTokenOperationRequestBody, RefreshTokenOperationOperationResponses>;
export type RefreshTokenOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<RefreshTokenOperationRequestParameters, RefreshTokenOperationRequestArrayParameters, RefreshTokenOperationRequestBody, RefreshTokenOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of refreshToken
 */
export const refreshTokenHandler = (
    firstHandler: RefreshTokenOperationChainedHandlerFunction,
    ...remainingHandlers: RefreshTokenOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'refreshToken'> => async (event: any, context: any, _callback?: any, additionalInterceptors: RefreshTokenOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as RefreshTokenOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as RefreshTokenOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return RefreshTokenRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as RefreshTokenOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(RefreshTokenResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for ResetPasswordOperation
 */
export interface ResetPasswordOperationRequestParameters {
}

/**
 * Multi-value query parameters for ResetPasswordOperation
 */
export interface ResetPasswordOperationRequestArrayParameters {
}

/**
 * Request body parameter for ResetPasswordOperation
 */
export type ResetPasswordOperationRequestBody = ResetPasswordRequest;

export type ResetPasswordOperation200OperationResponse = OperationResponse<200, undefined>;
export type ResetPasswordOperation400OperationResponse = OperationResponse<400, GenericError>;
export type ResetPasswordOperation403OperationResponse = OperationResponse<403, GenericError>;
export type ResetPasswordOperation500OperationResponse = OperationResponse<500, GenericError>;
export type ResetPasswordOperationOperationResponses = | ResetPasswordOperation200OperationResponse | ResetPasswordOperation400OperationResponse | ResetPasswordOperation403OperationResponse | ResetPasswordOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type ResetPasswordOperationHandlerFunction = LambdaHandlerFunction<ResetPasswordOperationRequestParameters, ResetPasswordOperationRequestArrayParameters, ResetPasswordOperationRequestBody, ResetPasswordOperationOperationResponses>;
export type ResetPasswordOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<ResetPasswordOperationRequestParameters, ResetPasswordOperationRequestArrayParameters, ResetPasswordOperationRequestBody, ResetPasswordOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of resetPassword
 */
export const resetPasswordHandler = (
    firstHandler: ResetPasswordOperationChainedHandlerFunction,
    ...remainingHandlers: ResetPasswordOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'resetPassword'> => async (event: any, context: any, _callback?: any, additionalInterceptors: ResetPasswordOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as ResetPasswordOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as ResetPasswordOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return ResetPasswordRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as ResetPasswordOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for TokenOperation
 */
export interface TokenOperationRequestParameters {
}

/**
 * Multi-value query parameters for TokenOperation
 */
export interface TokenOperationRequestArrayParameters {
}

/**
 * Request body parameter for TokenOperation
 */
export type TokenOperationRequestBody = TokenRequest;

export type TokenOperation200OperationResponse = OperationResponse<200, TokenResponse>;
export type TokenOperation400OperationResponse = OperationResponse<400, GenericError>;
export type TokenOperation403OperationResponse = OperationResponse<403, GenericError>;
export type TokenOperation500OperationResponse = OperationResponse<500, GenericError>;
export type TokenOperationOperationResponses = | TokenOperation200OperationResponse | TokenOperation400OperationResponse | TokenOperation403OperationResponse | TokenOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type TokenOperationHandlerFunction = LambdaHandlerFunction<TokenOperationRequestParameters, TokenOperationRequestArrayParameters, TokenOperationRequestBody, TokenOperationOperationResponses>;
export type TokenOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<TokenOperationRequestParameters, TokenOperationRequestArrayParameters, TokenOperationRequestBody, TokenOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of token
 */
export const tokenHandler = (
    firstHandler: TokenOperationChainedHandlerFunction,
    ...remainingHandlers: TokenOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'token'> => async (event: any, context: any, _callback?: any, additionalInterceptors: TokenOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as TokenOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as TokenOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return TokenRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as TokenOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(TokenResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};
/**
 * Single-value path/query parameters for VerifyTokenOperation
 */
export interface VerifyTokenOperationRequestParameters {
}

/**
 * Multi-value query parameters for VerifyTokenOperation
 */
export interface VerifyTokenOperationRequestArrayParameters {
}

/**
 * Request body parameter for VerifyTokenOperation
 */
export type VerifyTokenOperationRequestBody = VerifyTokenRequest;

export type VerifyTokenOperation200OperationResponse = OperationResponse<200, VerifyToken200Response>;
export type VerifyTokenOperation400OperationResponse = OperationResponse<400, GenericError>;
export type VerifyTokenOperation403OperationResponse = OperationResponse<403, undefined>;
export type VerifyTokenOperation500OperationResponse = OperationResponse<500, GenericError>;
export type VerifyTokenOperationOperationResponses = | VerifyTokenOperation200OperationResponse | VerifyTokenOperation400OperationResponse | VerifyTokenOperation403OperationResponse | VerifyTokenOperation500OperationResponse ;

// Type that the handler function provided to the wrapper must conform to
export type VerifyTokenOperationHandlerFunction = LambdaHandlerFunction<VerifyTokenOperationRequestParameters, VerifyTokenOperationRequestArrayParameters, VerifyTokenOperationRequestBody, VerifyTokenOperationOperationResponses>;
export type VerifyTokenOperationChainedHandlerFunction = ChainedLambdaHandlerFunction<VerifyTokenOperationRequestParameters, VerifyTokenOperationRequestArrayParameters, VerifyTokenOperationRequestBody, VerifyTokenOperationOperationResponses>;

/**
 * Lambda handler wrapper to provide typed interface for the implementation of verifyToken
 */
export const verifyTokenHandler = (
    firstHandler: VerifyTokenOperationChainedHandlerFunction,
    ...remainingHandlers: VerifyTokenOperationChainedHandlerFunction[]
): OperationApiGatewayLambdaHandler<'verifyToken'> => async (event: any, context: any, _callback?: any, additionalInterceptors: VerifyTokenOperationChainedHandlerFunction[] = []): Promise<any> => {
    const requestParameters = decodeRequestParameters({
        ...(event.pathParameters || {}),
        ...(event.queryStringParameters || {}),
    }) as unknown as VerifyTokenOperationRequestParameters;

    const requestArrayParameters = decodeRequestParameters({
        ...(event.multiValueQueryStringParameters || {}),
    }) as unknown as VerifyTokenOperationRequestArrayParameters;

    const demarshal = (bodyString: string): any => {
        return VerifyTokenRequestFromJSON(JSON.parse(bodyString));
    };
    const body = parseBody(event.body, demarshal, ['application/json',]) as VerifyTokenOperationRequestBody;

    const chain = buildHandlerChain(...additionalInterceptors, firstHandler, ...remainingHandlers);
    const response = await chain.next({
        input: {
            requestParameters,
            requestArrayParameters,
            body,
        },
        event,
        context,
        interceptorContext: {},
    });

    const marshal = (statusCode: number, responseBody: any): string => {
        let marshalledBody = responseBody;
        switch(statusCode) {
            case 200:
                marshalledBody = JSON.stringify(VerifyToken200ResponseToJSON(marshalledBody));
                break;
            case 400:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            case 403:
                break;
            case 500:
                marshalledBody = JSON.stringify(GenericErrorToJSON(marshalledBody));
                break;
            default:
                break;
        }

        return marshalledBody;
    };

    return {
        ...response,
        body: response.body ? marshal(response.statusCode, response.body) : '',
    };
};

export interface HandlerRouterHandlers {
  readonly cognitoCallback: OperationApiGatewayLambdaHandler<'cognitoCallback'>;
  readonly confirmAccount: OperationApiGatewayLambdaHandler<'confirmAccount'>;
  readonly confirmForgotPassword: OperationApiGatewayLambdaHandler<'confirmForgotPassword'>;
  readonly getUserPool: OperationApiGatewayLambdaHandler<'getUserPool'>;
  readonly identityProviders: OperationApiGatewayLambdaHandler<'identityProviders'>;
  readonly login: OperationApiGatewayLambdaHandler<'login'>;
  readonly loginExternal: OperationApiGatewayLambdaHandler<'loginExternal'>;
  readonly logout: OperationApiGatewayLambdaHandler<'logout'>;
  readonly refreshToken: OperationApiGatewayLambdaHandler<'refreshToken'>;
  readonly resetPassword: OperationApiGatewayLambdaHandler<'resetPassword'>;
  readonly token: OperationApiGatewayLambdaHandler<'token'>;
  readonly verifyToken: OperationApiGatewayLambdaHandler<'verifyToken'>;
}

export type AnyOperationRequestParameters = | CognitoCallbackRequestParameters| ConfirmAccountOperationRequestParameters| ConfirmForgotPasswordOperationRequestParameters| GetUserPoolRequestParameters| IdentityProvidersRequestParameters| LoginOperationRequestParameters| LoginExternalRequestParameters| LogoutRequestParameters| RefreshTokenOperationRequestParameters| ResetPasswordOperationRequestParameters| TokenOperationRequestParameters| VerifyTokenOperationRequestParameters;
export type AnyOperationRequestArrayParameters = | CognitoCallbackRequestArrayParameters| ConfirmAccountOperationRequestArrayParameters| ConfirmForgotPasswordOperationRequestArrayParameters| GetUserPoolRequestArrayParameters| IdentityProvidersRequestArrayParameters| LoginOperationRequestArrayParameters| LoginExternalRequestArrayParameters| LogoutRequestArrayParameters| RefreshTokenOperationRequestArrayParameters| ResetPasswordOperationRequestArrayParameters| TokenOperationRequestArrayParameters| VerifyTokenOperationRequestArrayParameters;
export type AnyOperationRequestBodies = | CognitoCallbackRequestBody| ConfirmAccountOperationRequestBody| ConfirmForgotPasswordOperationRequestBody| GetUserPoolRequestBody| IdentityProvidersRequestBody| LoginOperationRequestBody| LoginExternalRequestBody| LogoutRequestBody| RefreshTokenOperationRequestBody| ResetPasswordOperationRequestBody| TokenOperationRequestBody| VerifyTokenOperationRequestBody;
export type AnyOperationResponses = | CognitoCallbackOperationResponses| ConfirmAccountOperationOperationResponses| ConfirmForgotPasswordOperationOperationResponses| GetUserPoolOperationResponses| IdentityProvidersOperationResponses| LoginOperationOperationResponses| LoginExternalOperationResponses| LogoutOperationResponses| RefreshTokenOperationOperationResponses| ResetPasswordOperationOperationResponses| TokenOperationOperationResponses| VerifyTokenOperationOperationResponses;

export interface HandlerRouterProps<
  RequestParameters,
  RequestArrayParameters,
  RequestBody,
  Response extends AnyOperationResponses
> {
  /**
   * Interceptors to apply to all handlers
   */
  readonly interceptors?: ChainedLambdaHandlerFunction<
    RequestParameters,
    RequestArrayParameters,
    RequestBody,
    Response
  >[];

  /**
   * Handlers to register for each operation
   */
  readonly handlers: HandlerRouterHandlers;
}

const concatMethodAndPath = (method: string, path: string) => `${method.toLowerCase()}||${path}`;

const OperationIdByMethodAndPath = Object.fromEntries(Object.entries(OperationLookup).map(
  ([operationId, methodAndPath]) => [concatMethodAndPath(methodAndPath.method, methodAndPath.path), operationId]
));

/**
 * Returns a lambda handler which can be used to route requests to the appropriate typed lambda handler function.
 */
export const handlerRouter = (props: HandlerRouterProps<
  AnyOperationRequestParameters,
  AnyOperationRequestArrayParameters,
  AnyOperationRequestBodies,
  AnyOperationResponses
>): OperationApiGatewayLambdaHandler<OperationIds> => async (event, context) => {
  const operationId = OperationIdByMethodAndPath[concatMethodAndPath(event.requestContext.httpMethod, event.requestContext.resourcePath)];
  const handler = props.handlers[operationId];
  return handler(event, context, undefined, props.interceptors);
};
