import { Text, Title } from "@mantine/core";
import { ITractableTheme } from "@tractable/frame-ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { TractableLogo } from "./icons/TractableLogo";

/** Component to render when one or more required parameters on the login page
 * are not present */
const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("global.error.page_not_found.header");
  }, [t]);

  return (
    <div className={classes.container}>
      <div>
        <TractableLogo />
      </div>
      <div className={classes.textBlock}>
        <Title className={classes.title}>
          {t("global.error.page_not_found.header")}
        </Title>
        <div className={classes.detailBlock}>
          <Text className={classes.detail}>
            {t("global.error.page_not_found.body")}
          </Text>
        </div>
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  container: {
    paddingLeft: "24px",
    paddingTop: "24px",
  },
  textBlock: {
    marginLeft: "100px",
    marginTop: "300px",
  },
  title: {
    color: theme.colour.Black,
  },
  detail: {
    color: theme.colour.Grey90,
    marginTop: "16px",
  },
  detailBlock: {
    width: "462px",
  },
}));

export default NotFound;
