import {
  Configuration,
  DefaultApi,
} from "@tractableai/auth-identity-broker-client";
import { PropsWithChildren, useEffect, useState } from "react";
import { createSafeContext } from "react-safe-context";

import { RuntimeConfigContext } from "./ConfigProvider";

type ApiClientContext = {
  apiClient: DefaultApi;
};

/** Context to provide the API client to components that will need it */
export const ApiClientContext =
  createSafeContext<ApiClientContext>("ApiClientContext");

export const ApiClientProvider: React.FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [client, setClient] = useState<DefaultApi | undefined>(undefined);

  const config = RuntimeConfigContext.useValue();

  useEffect(() => {
    setClient(
      new DefaultApi(
        new Configuration({
          basePath: config.api.baseUrl,
          fetchApi: window.fetch.bind(window),
        })
      )
    );
  }, [config]);

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (
    <ApiClientContext.Provider value={{ apiClient: client! }}>
      {children}
    </ApiClientContext.Provider>
  );
  /* eslint-enable */
};
