import { Alert, Flex, SimpleGrid, Text } from "@mantine/core";
import { ITractableTheme } from "@tractable/frame-ui";
import { colours } from "@tractable/frame-ui/build/theme/colour";
import { PasswordPolicy } from "@tractableai/auth-identity-broker-client";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import Check from "./icons/Check";
import CheckCircle from "./icons/CheckCircle";
import Ellipse from "./icons/Ellipse";

/** Represents the validity of a proposed new password.
 * A value of true means that the rule is required and is satisfied.
 * A value of false means that the rule is required and is not satisfied.
 * An undefined value means that the rule is not required.
 */
export type PasswordValidity = {
  minimumLength?: boolean;
  requireUppercase?: boolean;
  requireNumber?: boolean;
  requireLowercase?: boolean;
  requireSymbol?: boolean;
};

export type PasswordValidationProps = {
  validity: PasswordValidity;

  /** How many characters are required */
  minimumLength?: number;

  /** Whether the user has entered any password in the input */
  passwordPresent: boolean;
};

const passwordValidationRegexps = {
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  number: /[0-9]/,

  // AWS definition of a special character/symbol:
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  symbol: /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/,
};

/** Calculate the validity of the proposed new password. This is used as both
 * an input to a form's validation function, and to render a component that
 * displays the validation state. */
export const calculateNewPasswordValidity = (
  passwordPolicy: PasswordPolicy,
  password: string
): PasswordValidity => {
  const validity: PasswordValidity = {};
  if (passwordPolicy.minimumLength) {
    validity.minimumLength = password.length >= passwordPolicy.minimumLength;
  }

  if (passwordPolicy.requireLowercase) {
    validity.requireLowercase =
      passwordValidationRegexps.lowercase.test(password);
  }

  if (passwordPolicy.requireUppercase) {
    validity.requireUppercase =
      passwordValidationRegexps.uppercase.test(password);
  }

  if (passwordPolicy.requireNumbers) {
    validity.requireNumber = passwordValidationRegexps.number.test(password);
  }

  if (passwordPolicy.requireSymbols) {
    validity.requireSymbol = passwordValidationRegexps.symbol.test(password);
  }

  return validity;
};

const PasswordValidation = (props: PasswordValidationProps) => {
  const { t } = useTranslation();
  const labels: Record<keyof PasswordValidity, string> = {
    minimumLength: t("create_account.password_rule.min_characters", {
      number: props.minimumLength,
    }),
    requireUppercase: t("create_account.password_rule.uppercase"),
    requireNumber: t("create_account.password_rule.one_number"),
    requireLowercase: t("create_account.password_rule.lowercase"),
    requireSymbol: t("create_account.password_rule.special_characters"),
  };

  const classes = useStyles();

  // For each required rule, generate an icon (depending on whether the rule is
  // satisfied) and explanatory text. These will be displayed in a grid
  const validationItems = Object.entries(props.validity).map(
    ([key, isValid]) => {
      const icon = isValid ? (
        <Check className={classes.icon} colour={colours.green[40]} />
      ) : (
        <Ellipse className={classes.icon} colour={colours.purple[30]} />
      );
      const text = labels[key as keyof PasswordValidity];
      return (
        <Flex key={key} align="center">
          {icon}
          <Text
            data-testid="new-password-rule"
            className={classes.text}
            fz="xs"
          >
            {text}
          </Text>
        </Flex>
      );
    }
  );

  if (!props.passwordPresent || Object.values(props.validity).includes(false)) {
    return (
      <SimpleGrid cols={2} className={classes.validation}>
        {validationItems}
      </SimpleGrid>
    );
  }

  // If the password is valid, hide the rules and show a success message
  return (
    <div className={classes.successWrapper}>
      <Alert
        icon={<CheckCircle colour={colours.green[50]} />}
        className={classes.successAlert}
      >
        {t("create_account.secure")}
      </Alert>
    </div>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  validation: {
    color: theme.colour.Black,
    gap: "4px 16px",
  },
  icon: {
    width: "16px",
  },
  text: {
    marginLeft: "4px",
  },
  successWrapper: {
    "& .mantine-Alert-wrapper": {
      alignItems: "center",
    },
    "& .mantine-Alert-icon": {
      marginRight: "8px",
    },
  },
  successAlert: {
    borderColor: "#B4D0B9",
    background: "#E3F5E1",
    marginTop: "16px",
  },
}));

export default PasswordValidation;
