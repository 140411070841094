import {
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Loader,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ITractableTheme } from "@tractable/frame-ui";
import {
  IdentityProvider,
  LoginRequestEnvEnum,
  ResponseError,
} from "@tractableai/auth-identity-broker-client";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link, useSearchParams } from "react-router-dom";

import { ApiClientContext } from "./context/ApiClientProvider";
import ErrorAlert from "./ErrorAlert";
import { TractableLogo } from "./icons/TractableLogo";
import NotFound from "./NotFound";
import { errorNotification } from "./notifications";
import { commonStyles } from "./styles";
import { guessClientId } from "./utils/email";
import { idpDisplayName } from "./utils/idp";
import { productDisplayName } from "./utils/product";
import { navigateToExternalUrl } from "./utils/url";

/** Describes the current step of the login flow */
type LoginStep =
  | "enter_email" // User is prompted to enter their email address
  | "enter_client_id" // User is prompted to enter their client ID (if we can't determine it)
  | "enter_password" // User is prompted to enter their password, or choose an external provider
  | "enter_impersonated_client_id"; // User is prompted to enter a client ID to "impersonate" (only for TR users)

type LoginForm = {
  email: string;
  password: string;
  clientId: string;
  // This field is optional, but Mantine form only works with defined values. So
  // treat an empty string as "undefined" and don't send it to the API in that
  // case.
  impersonatedClientId: string;
};

/** The entire state of the page, used to resume a partially-submitted form when
 * the user resets their password partway though. */
export type LoginState = Omit<LoginForm, "password"> & {
  productId: string;
  codeChallenge: string;
  env: LoginRequestEnvEnum;
  redirectUri: string;
  step: LoginStep;
  identityProviders: Array<IdentityProvider>;
};

type LoginProps = {
  loginState?: LoginState;
  setLoginState: (state: LoginState) => void;
};

const Login = (props: LoginProps) => {
  const { loginState, setLoginState } = props;
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Note that we use the saved component state, if it exists, to initialize
  // these local state variables. This allows the user to resume this form where
  // they left off after resetting their password.
  const [loginStep, setLoginStep] = useState<LoginStep>(
    loginState?.step ?? "enter_email"
  );
  const [identityProviders, setIdentityProviders] = useState<
    Array<IdentityProvider>
  >(loginState?.identityProviders ?? []);

  // When true, show an error notification
  const [authError, setAuthError] = useState<string | undefined>(undefined);

  // This is to work around a false-positive warning. Can remove this when we
  // upgrade to React 18. See https://github.com/facebook/react/pull/22114
  const isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // These parameters are required. If any is missing, show an error page.
  // Prioritize the saved state of the component over query string params.
  const productId = loginState?.productId ?? searchParams.get("product_id");
  const redirectUri =
    loginState?.redirectUri ?? searchParams.get("redirect_uri");
  const codeChallenge =
    loginState?.codeChallenge ?? searchParams.get("code_challenge");
  const env =
    loginState?.env ?? (searchParams.get("env") as LoginRequestEnvEnum);

  if (!productId || !redirectUri || !codeChallenge || !env) {
    return <NotFound />;
  }

  // To narrow the type to just `string`
  const definedProductId = productId;

  // Optionally provided in the query string; if not, we have to figure it out
  // ourselves via email regexp or asking the user
  const queryStringClientId = searchParams.get("client_id");

  const { apiClient } = ApiClientContext.useValue();

  const form = useForm<LoginForm>({
    initialValues: {
      email: loginState?.email ?? "",
      password: "",
      clientId: loginState?.clientId ?? queryStringClientId ?? "",
      impersonatedClientId: "",
    },
    validate: (values: LoginForm) => {
      if (loginStep === "enter_email") {
        return {
          // Simple validation to avoid giving an error when we shouldn't (like for
          // `+` in the email, etc). This is just used to auth against existing
          // Cognito accounts, so it doesn't need to be that strict
          email: /.@./.test(values.email) ? null : t("login.error.email"),
        };
      } else if (loginStep === "enter_client_id") {
        return {
          clientId: values.clientId ? null : t("login.error.client_id"),
        };
      } else if (loginStep === "enter_password") {
        return {
          password: values.password ? null : t("login.error.password"),
        };
      }
      return {};
    },
  });

  /** Handles the user clicking the 'log in' button, which can do different
   * things depending on the context. It can advance the 'step' of the login flow,
   * which will cause the component to render different fields, or it can submit a
   * username/password login request to the API. */
  const handleSubmit = async (values: LoginForm) => {
    /** Determine what IdPs (signin options) are available, and proceed as appropriate. */
    async function getIdentityProviders(clientId: string) {
      // NOTE: we have to explicitly pass in the client ID to use in the API call,
      // as the one in `values` might still be undefined if the user doesn't
      // explicitly enter the value in the form.

      setLoading(true);

      try {
        const idpResult = await apiClient.identityProviders({
          clientId,
          env,
          productId: definedProductId,
        });

        setIdentityProviders(idpResult.identityProviders);

        // Special case: if only a single external IdP is supported, immediately
        // log the user in rather than proceeding to the next step
        const hasSingleExternalIdp =
          idpResult.identityProviders.length === 1 &&
          idpResult.identityProviders[0].type === "external";

        if (clientId === "tractable") {
          setLoading(false);
          setLoginStep("enter_impersonated_client_id");
        } else if (hasSingleExternalIdp) {
          await handleExternalLogin(idpResult.identityProviders[0]);
        } else if (isMountedRef.current) {
          setLoading(false);
          setLoginStep("enter_password");
        }
      } catch (e) {
        errorNotification(t("error.generic"));
        setLoading(false);
      }
    }

    switch (loginStep) {
      case "enter_email": {
        // If we already have a clientId from the query string, just use that.
        // Otherwise guess it from the email address
        const clientIdFromEmail = guessClientId(values.email);

        // Exception: if the email is @tractable.ai, use the client ID
        // 'tractable', regardless of the query string. This is so Tractable
        // members can log into products for any client, regardless of whether
        // the product redirects with a client_id in the query string.
        const maybeClientId =
          clientIdFromEmail === "tractable"
            ? "tractable"
            : values.clientId || clientIdFromEmail;

        // If we still cannot determine the client, ask the user to enter it
        if (!maybeClientId) {
          setLoginStep("enter_client_id");
        } else {
          // Don't need to get the client ID from the user, just set the value
          // (that we either got from the query string or from guessing) in the
          // form directly
          form.setFieldValue("clientId", maybeClientId);
          await getIdentityProviders(maybeClientId);
        }
        break;
      }
      case "enter_client_id": {
        await getIdentityProviders(values.clientId);
        break;
      }
      case "enter_password": {
        setLoading(true);
        setAuthError(undefined);
        try {
          const { password, clientId, impersonatedClientId } = values;
          const result = await apiClient.login({
            loginRequest: {
              codeChallenge,
              password,
              username: values.email,
              productId,
              clientId,
              redirectUri,
              env,
              impersonatedClientId: impersonatedClientId || undefined,
            },
          });
          navigateToExternalUrl(`${redirectUri}?code=${result.code}`);
        } catch (e) {
          console.log(e);
          setLoading(false);
          if (e instanceof ResponseError && e.response.status === 429) {
            setAuthError(t("error.too_many_failed_attempts") ?? undefined);
          } else {
            setAuthError(t("login.error.invalid") ?? undefined);
          }
        }
        break;
      }
      case "enter_impersonated_client_id": {
        const hasSingleExternalIdp =
          identityProviders.length === 1 &&
          identityProviders[0].type === "external";
        if (hasSingleExternalIdp) {
          await handleExternalLogin(identityProviders[0]);
        } else if (isMountedRef.current) {
          setLoading(false);
          setLoginStep("enter_password");
        }
        break;
      }
    }
  };

  const handleExternalLogin = async (idp: IdentityProvider) => {
    try {
      setLoading(true);
      const { clientId, impersonatedClientId } = form.values;
      const result = await apiClient.loginExternal({
        clientId,
        impersonatedClientId: impersonatedClientId || undefined,
        codeChallenge,
        env,
        // We know that external IdPs should always have a name
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        identityProvider: idp.name!,
        productId,
        redirectUri,
      });
      // NOTE: don't need to set loading back to false in the success case
      // because we just redirect them
      navigateToExternalUrl(result.url);
    } catch (e) {
      errorNotification(t("error.generic"));
      setLoading(false);
    }
  };

  const handleChangeEmailClick = () => {
    setLoginStep("enter_email");
    setAuthError(undefined);
    form.setFieldValue("password", "");
  };

  const classes = useStyles();

  const emailComponent =
    loginStep === "enter_email" ? (
      <TextInput
        autoFocus={true}
        label={t("login.email")}
        className={classes.textInput}
        disabled={loading}
        {...form.getInputProps("email")}
      />
    ) : (
      <Flex justify="space-between">
        <Text>{form.values.email}</Text>
        <Button
          className={classes.changeEmailButton}
          compact
          variant="subtle"
          disabled={loading}
          onClick={handleChangeEmailClick}
        >
          {t("login.change")}
        </Button>
      </Flex>
    );

  const clientIdComponent = loginStep === "enter_client_id" && (
    <>
      <TextInput
        label={t("login.client_id")}
        className={classes.textInput}
        placeholder=""
        {...form.getInputProps("clientId")}
      />
      <Text fz="xs">{t("login.client_id.help")}</Text>
    </>
  );

  const impersonatedClientIdComponent = loginStep ===
    "enter_impersonated_client_id" && (
    <TextInput
      label={t("login.impersonate.client_id.help")}
      className={classes.textInput}
      placeholder=""
      {...form.getInputProps("impersonatedClientId")}
    />
  );

  const errorComponent = authError && (
    <ErrorAlert message={authError} className={classes.errorAlert} />
  );

  const supportsNativeLogin = identityProviders.find(
    (idp) => idp.type === "native"
  );

  const supportsExternalLogin = identityProviders.find(
    (idp) => idp.type === "external"
  );

  const passwordComponent = loginStep === "enter_password" &&
    supportsNativeLogin && (
      <>
        <TextInput
          label={t("login.password")}
          type="password"
          className={classes.textInput}
          disabled={loading}
          {...form.getInputProps("password")}
        />
        <div
          className={classNames(classes.forgotPasswordLink, {
            [classes.disabledLink]: loading,
          })}
        >
          <Link
            onClick={() => {
              setLoginState({
                clientId: form.values.clientId,
                productId,
                env,
                codeChallenge,
                redirectUri,
                step: loginStep,
                email: form.values.email,
                identityProviders,
                impersonatedClientId: form.values.impersonatedClientId,
              });
            }}
            to={`/forgot_password?client_id=${form.values.clientId}&env=${env}`}
          >
            {t("login.forgot_password")}
          </Link>
        </div>
      </>
    );

  const externalLoginButtons =
    loginStep === "enter_password" &&
    identityProviders
      .filter((idp) => idp.type === "external")
      .map((idp) => {
        // external IdPs should all have a name, but that's not reflected in the types
        return (
          <Button
            key={idp.name}
            className={classes.externalLoginButton}
            disabled={loading}
            variant="default"
            fullWidth={true}
            onClick={() => handleExternalLogin(idp)}
          >
            {t("login.sso", { ssoProvider: t(idpDisplayName(idp.name ?? "")) })}
          </Button>
        );
      });

  // Show a divider only when we have both a native login and an external login available
  const divider = loginStep === "enter_password" &&
    supportsNativeLogin &&
    supportsExternalLogin && (
      <Divider className={classes.divider} label="or" labelPosition="center" />
    );

  const productName = productDisplayName(productId);

  useEffect(() => {
    document.title = t("login.title", { product: productName });
  }, [t, productName]);

  return (
    <Center className={classes.container}>
      <Card className={classNames(classes.card, classes.loginCard)} shadow="lg">
        <Center className={classes.tractableLogo}>
          <TractableLogo />
        </Center>
        {productName && (
          <Text
            ta="center"
            className={classes.cardTitle}
            data-testid="welcome-message"
          >
            {t("login.title", { product: productName })}
          </Text>
        )}
        <form onSubmit={form.onSubmit(handleSubmit)}>
          {externalLoginButtons}
          {divider}
          {emailComponent}
          {clientIdComponent}
          {impersonatedClientIdComponent}
          {passwordComponent}
          {errorComponent}
          <Button
            type="submit"
            className={classes.submitButton}
            disabled={loading}
            fullWidth={true}
          >
            {loading ? <Loader color="white" size="xs" /> : t("login.button")}
          </Button>
        </form>
      </Card>
    </Center>
  );
};

const useStyles = createUseStyles((theme: ITractableTheme) => ({
  ...commonStyles(theme),
  externalLoginButton: {
    backgroundColor: theme.colour.White,
    borderColor: theme.colour.Grey30,
    color: theme.colour.Black,
    "&:not(:first-child)": {
      marginTop: "16px",
    },
  },
  divider: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  changeEmailButton: {
    marginBottom: "24px",
    color: theme.colour.Purple60,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&[data-disabled]": {
      backgroundColor: "transparent",
    },
  },
  errorAlert: {
    marginTop: "28px",
  },
  forgotPasswordLink: {
    marginTop: "12px",
    "& a": {
      textDecoration: "none",
      color: theme.colour.Purple60,
      fontWeight: 500,
    },
  },
  disabledLink: {
    pointerEvents: "none",
  },
  loginCard: {
    width: "421px",
  },
}));

export default Login;
