/* tslint:disable */
/* eslint-disable */
/**
 * Tractable Identity Broker API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityProvider
 */
export interface IdentityProvider {
    /**
     * 
     * @type {string}
     * @memberof IdentityProvider
     */
    type: IdentityProviderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IdentityProvider
     */
    name?: string;
}

/**
* @export
* @enum {string}
*/
export type IdentityProviderTypeEnum =
    'native' | 
    'external'



export function IdentityProviderFromJSON(json: any): IdentityProvider {
    return IdentityProviderFromJSONTyped(json, false);
}

export function IdentityProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function IdentityProviderToJSON(value?: IdentityProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
    };
}

